@import '../../sass/variables';
@import '../../sass/mixins';

.dialog-action-text {
  font-weight: 500;
  color: $primary-blue;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
}

.dialog-title {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &__title {
      font-weight: bold;
      display: block;
    }

    &__text {
      text-align: left;
      margin-top: 1px;
      margin-bottom: 2rem;
    }
  }
}

.modal-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.button-load {
  @include button;
  color: white;
  font-weight: bold;
  background-color: #0082f5;
  border-radius: 50px;
  padding: 10px 15px;
  margin-left: 0.5rem;

  &:hover {
    cursor: pointer;
  }
}

.button-close {
  @include button;
  color: white;
  font-weight: bold;
  background-color: red;
  border-radius: 50px;
  padding: 10px 15px;
  margin-left: 0.5rem;

  &:hover {
    cursor: pointer;
  }
}

.button-cancel {
  @include button;
  color: grey;
  font-weight: bold;
  background-color: unset;
  border-radius: 50px;
  padding: 10px;
  margin-left: 0.5rem;

  &:hover {
    cursor: pointer;
  }
}

.swal2-close {
  &:hover {
    color: $primary-blue !important;
  }

  &:focus {
    outline: none;
    background-color: none;
    color: none;
    border: none;
  }
}

// CHECK STYLE
.check-container {
  display: block;
  position: relative;
  padding-left: 1.8rem;
  font-size: 1.3rem;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $primary-blue;
      border-radius: 5px;
      transform: rotate(0deg) scale(1);
      opacity: 1;
      border: 2px solid $primary-blue;
    }
  }

  .checkmark {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: $secondary-blue;
    border-radius: 5px;
    border: 2px solid $primary-blue;
    transition: all 0.3s ease-out;

    &::after {
      position: absolute;
      content: '';
      left: 12px;
      top: 12px;
      height: 0;
      width: 0;
      border-radius: 5px;
      border: solid $secondary-blue;
      border-width: 0 3px 3px 0;
      transform: rotate(0deg) scale(0);
      opacity: 1;
      transition: all 0.3s ease-out;
    }
  }
}

.check-container input:checked ~ .checkmark::after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid $secondary-blue;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}
