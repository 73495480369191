// COLORS
// --------------------------------------------------
$primary-blue: #0082f5;
$secondary-blue: #eef6ff;
$thirdary-blue: #031f61;

// DIMENSIONS
// --------------------------------------------------
$phone: 480px;
$tablet: 768px;
$desktop: 1024px;
