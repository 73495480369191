@import '../../sass/variables';
@import '../../sass/mixins';

.header {
  &__topbar {
    background-color: $primary-blue;
    display: flex;
    align-items: center;
    padding: 0.7rem 2rem;

    .logo {
      display: flex;
      align-items: baseline;
      flex: 1 0 auto;

      img {
        width: 8rem;
      }

      &__text {
        display: flex;
        span {
          margin-left: 0.5rem;
          font-weight: 500;
          letter-spacing: 1px;
          color: white;
        }
      }
    }

    .logout {
      span {
        color: white;
        font-weight: bold;
        font-size: 1.1rem;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__hero {
    display: flex;
    background-color: white;
    flex-direction: column;
    padding: 2rem;
    align-items: center;
    height: fit-content;

    @include phone {
      flex-direction: row;
      padding: 2rem 5rem;
    }

    &__img {
      width: 40px;
      margin-right: 1rem;

      @include phone {
        width: 80px;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include phone {
        width: 80%;
      }

      @include tablet {
        width: 30%;
      }

      p {
        font-size: 1.1rem;
        font-weight: bold;
        color: $thirdary-blue;
        margin: 0.5rem 0;
      }
    }
  }
}

.card_container {
  display: flex;
  border-radius: 5px;
  border: 0.5px solid lighten($primary-blue, 25%);
  width: 100%;
  padding: 0.2rem;
  justify-self: center;
  align-items: center;
  justify-content: space-evenly;

  p {
    padding: 0.2rem;
    font-size: medium;
    font-weight: 500;
  }

  .circle-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $thirdary-blue;
    font-weight: bold;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(202, 202, 202);
  }
}

.principal-container {
  padding: 1.5rem;
}
