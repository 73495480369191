@import 'variables';

html {
  box-sizing: border-box;
}
*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $secondary-blue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  width: 100%;
}

input,
textarea,
select,
input {
  border: none;
  font-size: 1rem;
  color: $thirdary-blue;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #eef6ff9a;
  border-right: 1px solid rgba(128, 128, 128, 0.356);
  border-left: 1px solid rgba(128, 128, 128, 0.356);
  margin: 0 2px;
}

input:focus,
textarea:focus,
select:focus,
input:focus {
  outline: none;
}
