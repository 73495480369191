@import '../../sass/variables';

.loading-container {
  background-color: darken($secondary-blue, 10%);
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: background-color 5s ease-out;
  opacity: 1;

  .circle {
    z-index: 1201;
    border: 4px solid white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-left-color: $primary-blue;

    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
