@import 'variables';

@mixin phone {
  @media only screen and (min-width: #{$phone}) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: #{$tablet}) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: #{$desktop}) {
    @content;
  }
}

@mixin button {
  border: none;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
}
