@import '../sass/variables';

.typeorders_title {
  margin: 0;
  padding: 0;
  color: $primary-blue;
  font-weight: 500;
  font-size: 1.7rem;
  margin-left: 1rem;
  text-shadow: 1px 1px 4px rgba(150, 150, 150, 0.4);
}

.orders_container {
  overflow-y: hidden;
  margin-top: 1rem;
  background-color: white;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 14px -1px rgba(0, 0, 0, 0.05);
  border: 0.5px solid lighten($primary-blue, 35%);

  .group-nav-top {
    display: flex;
    flex-wrap: wrap;
  }

  &__nav {
    flex: 1 0 auto;
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 20px;
        border-radius: 10px;
        border-right: 1px solid lighten($primary-blue, 25%);
      }

      a {
        color: grey;
        text-decoration: none;
        font-weight: 500;

        &.active {
          color: $primary-blue;
        }

        &:hover {
          color: $primary-blue;
        }
      }
    }
  }

  &__filters {
    display: flex;

    .filter-input {
      width: 300px;
    }
  }

  &__table {
    border-collapse: collapse;
    width: 100%;

    .table-header {
      border-top: 1px dotted lighten($primary-blue, 25%);
      border-bottom: 1px dotted lighten($primary-blue, 25%);
      th {
        padding: 20px;
        color: $thirdary-blue;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .table-content {
      border-bottom: 1px dotted lighten($primary-blue, 25%);
      &:last-of-type {
        border-bottom: unset;
      }

      td {
        padding: 20px;
        color: rgb(83, 83, 83);
        font-weight: 400;
        text-align: center;
      }
    }
  }
}
