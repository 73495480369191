@import '../../sass/variables';
@import '../../sass/mixins';

.dialog-action-text {
  font-weight: 500;
  color: $primary-blue;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }
}

.dialog-title {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &__title {
      font-weight: bold;
      display: block;
    }

    &__text {
      text-align: left;
      margin-top: 1px;
      margin-bottom: 2rem;
    }
  }
}

.modal-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.swal2-close {
  &:hover {
    color: $primary-blue !important;
  }

  &:focus {
    outline: none;
    background-color: none;
    color: none;
    border: none;
  }
}
